<div
  *ngIf="!(showMaintenance$ | async)"
  class="result-as-of"
>
  <div class="container">
    <div class="row ml-0-1366">
      <div
        *ngIf="showTimer"
        id="circle-timer-svg"
      >
        <svg viewBox="-10 -10 120 120">
          <path
            [style.stroke]="!showTimer ? innerStrokeGrey : innerStroke"
            d="M 50,50 m 0,-48 a 48,48 0 1 1 0,96 a 48,48 0 1 1 0,-96"
            stroke-width="17"
            fill-opacity="0"
          ></path>
          <path
            [style.stroke]="outerStroke"
            [style.stroke-dashoffset]="strokeOffset"
            d="M 50,50 m 0,-48 a 48,48 0 1 1 0,96 a 48,48 0 1 1 0,-96"
            stroke-width="18"
            fill-opacity="0"
            style="stroke-dasharray: 301.635, 301.635"
          ></path>
        </svg>

        <mat-spinner
          *ngIf="isWheelScannerLoading$ | async"
          class="blue-spinner additional-wheel-timer-progress mobile"
          diameter="32"
        ></mat-spinner>

        <mat-spinner
          *ngIf="isWheelScannerLoading$ | async"
          class="blue-spinner additional-wheel-timer-progress desktop"
          diameter="26"
        ></mat-spinner>

        <span id="per-number">{{ showTimer ? timerFor2minutes : '-' }}</span>
      </div>

      <div class="progress-detail-txt">
        <!--  (Version 1, 2) for Market closed state  -->
        @if (lastUpdatedDate) {
          <span [ngStyle]="{ display: showTimer ? 'block' : 'none' }">
            Result as of<br />
            {{ lastUpdatedDate }} &nbsp; • &nbsp; {{ lastUpdatedTime }} ET
          </span>
        }

        <!--  (Version 2) for Market closed state  -->

        <!--  show last-update block when market is closed and there is last update date  -->
        @let showLastUpdatedDate = !showTimer && lastUpdatedDate;
        <!--  show next-run block when when market is closed and there is no last update date (null), cleanup period 9:26 - 9:30  -->
        @let showNextRunDate = !showTimer && !lastUpdatedDate && nextDayDate;

        @if (showLastUpdatedDate) {
          <div class="wheel-labels-container">
            <div class="grey-bg last-updated-label">
              <div class="label-container">
                <mat-icon
                  [svgIcon]="'stash-last-updates-duotone'"
                  class="stash-last-updates-icon"
                ></mat-icon>
                <div class="text-label">Last Update</div>
                <!--  TODO: use computed for tooltip text, instead of '+' here  -->
                <mat-icon
                  [svgIcon]="'question-mark-wheel'"
                  [matTooltip]="'Next run: ' + nextDayDate + ', ' + nextDayTime + ' ET'"
                  class="stash-last-updates-icon"
                ></mat-icon>
              </div>

              <div class="date-time-container">{{ lastUpdatedDateTime }} ET</div>
            </div>
          </div>
        }

        @if (showNextRunDate) {
          <div class="wheel-labels-container">
            <div class="grey-bg last-updated-label">
              <div class="label-container">
                <mat-icon
                  [svgIcon]="'stash-last-updates-duotone-second'"
                  class="stash-last-updates-icon"
                ></mat-icon>
                <div class="text-label">Next Run</div>
              </div>

              <div class="date-time-container">{{ nextDayDate }}, {{ nextDayTime }} ET</div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
