@import '../../../../scss/values';

.data-window-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  @media screen and (min-width: $tablet-992px-width-trigger) {
    overflow-y: auto;

    &.shadow-bottom:after {
      display: inline-block;
      content: "";
      position: absolute;
      bottom: 0;
      width: calc(100% - 10px);
      height: 20px;
      background-image: var(--tl-datawindow-shadow-when-content-hides-on-bottom);
      z-index: 2;
      pointer-events: none;
    }
  }

  .list-table {
    flex: 1 1 100%;
    border-top: 1px solid var(--dark-border-color);

    @media screen and (min-width: $tablet-992px-width-trigger) {
      overflow: initial;
    }

    .tableScroll {
      display: flex;
      flex-direction: column;
    }

    .no-options-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      .no-options {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;
        padding: 20px;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;

        .title {
          display: inline-block;
          width: -webkit-max-content;
          width: -moz-max-content;
          width: max-content;
          color: var(--font-dark-color);
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          text-align: left;
        }

        .details {
          color: var(--light-color);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
        }

        .details.list {
          display: inline-block;
          width: -webkit-max-content;
          width: -moz-max-content;
          width: max-content;
          margin-left: 16px;
          text-align: left;

          li {
            list-style: disc;
          }
        }
      }
    }
  }

  .list-table .tableScroll th,
  .list-table .tableScroll td {
    border-bottom: none;
    padding: 3px 14px !important;

    &.strike {
      padding: 3px 16px !important;
    }
  }

  .list-table .tableScroll tr:last-child th,
  .list-table .tableScroll tr:last-child td {
    border-bottom: 1px solid var(--dark-border-color);
  }

  .list-table .tableScroll tbody tr th {
    min-width: 80px;
    background: var(--info-bg-color);
  }

  .list-table .tableScroll tbody tr th {
    min-width: 130px;
    max-width: 130px;
    white-space: nowrap;
  }

  .strike {
    position: relative;

    .strike-inner {
      padding: 0 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .option-source {
      margin-left: 2px;
      margin-right: -17px;
      display: inline-block;
      width: 14px;
      height: 14px;
      font-size: 10px;
      line-height: 14px;
      border-radius: 8px;
    }

    .option-source.conservative {
      background-color: var(--green-light-bg);
    }

    .option-source.aggressive {
      background-color: var(--light-violet-bg);
    }
  }

  table {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    flex-grow: 1;
    max-height: 360px;

    tr {
      th {
        height: -webkit-fit-content !important;
        height: -moz-fit-content !important;
        height: fit-content !important;
      }
    }
  }

  .mdc-button.calculator-plus-btn {
    --mdc-text-button-container-height: 20px;
  }
}

@media screen and (min-width: $tablet-992px-width-trigger) {
  .list-table .tableScroll {
    padding-bottom: 0;
    overflow-y: hidden;
  }
}

.top-indicator,
.bottom-indicator {
  height: 1px;
}
