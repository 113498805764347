@import '../../../../scss/values';
@import '../../../../scss/mixins/images';

.additional-wheel-timer-progress {
  @media screen and (max-width: $tablet-max-width-trigger) {
    &.desktop {
      display: none !important;
    }
  }

  @media screen and (min-width: $desktop-min-width-trigger) {
    &.mobile {
      display: none !important;
    }
  }
}

.wheel-labels-container {
  gap: 4px;
  .grey-bg {
    background-color: rgba(120, 123, 134, 0.1);
    padding: 4px 6px;
    border-radius: 6px;
    .label-container {
      display: flex;
      gap: 2px;
      align-items: center;
      color: var(--content-color-2);
      .mat-icon.stash-last-updates-icon {
        @include apply-icon-size(16px);
        &:last-child {
          margin-left: 3px;
          color: var(--light-svg);
        }
      }

      .mat-icon {
        color: var(--content-color-2);
      }
      .text-label {
        font-weight: 700;
        font-size: 12px;
        line-height: 100%;
      }
    }
    .date-time-container {
      margin-top: 2px;
      color: var(--content-color-2);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      font-size: 13px;
    }
  }
}
