@import '../../../../scss/values';
@import '../../../../scss/mixins/fonts';

app-wheel-scanner {
  .cons-agg.inner {
    height: 100%;
  }

  .scanner-tab-items-number {
    display: inline-block;
    min-width: 24px;
    margin-left: 5px;
    padding: 2px 2px 0;
    line-height: 150%;
    letter-spacing: 0.1px;
    border-radius: 4px;
    background-color: var(--bar-bg-color);
  }

  .ws-tabbing .mat-tab-label-active .mat-tab-label-content {
    .scanner-tab-items-number {
      background-color: var(--light-bg-17);
    }
  }

  // custom tablet view breakpoint, refactor it after global searching for "991px" and "992px"
  @media (min-width: $tablet-992px-width-trigger) {
    .mat-tab-group.ws-tabbing.cons-aggress .mat-tab-label-active {
      border-top: 0 !important;
      border-right: 0 !important;
      border-left: 0 !important;
      border-bottom: 2px solid var(--primary-color) !important;
    }
  }
}

.grouped-watchlist-container {
  display: block;
  height: 100%;
}

.label-portfolio {
  display: flex;
  align-items: center;
  gap: 4px;

  .portfolio-info-icon {
    margin-bottom: 1px;
  }
}

.no-data-msg {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  @media screen and (max-width: $tablet-992px-width-trigger) {
    margin-top: 10px;
  }

  .text-wrapper {
    flex: 1 1 336px;
    max-width: 336px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .title {
      margin: 0;
      @include font-bold(14px, 20px, -0.14px, var(--main-font-color));
    }
  }

  .all-good {
    margin-top: 0;
    justify-content: flex-start;
  }

  &[hidden] {
    display: none;
  }
}

.no-data-img {
  min-width: 97px;
  flex: 1 1 162px;
  max-width: 162px;
  background: var(--wheel-scanner-no-stocks);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  aspect-ratio: 81/70;
}

