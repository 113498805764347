@import '../../../../scss/values';
@import '../../../../scss/mixins/fonts';

@import '../../../../scss/values';
@import '../../../../scss/mixins/fonts';

app-wheel-chart {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chart-wheel-strategy-container {
  display: block;
  width: 100%;
  height: 100%;

  @media screen and (max-width: $tablet-991px-width-trigger) {
    height: max(450px, 60dvh);
  }

  @media screen and (max-width: $mobile-max-width-trigger) {
    margin-inline: auto;
    width: 100%;
    border: 1px solid var(--dark-border-color);
    border-top: 0;
  }
}

.chart-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.low-high-indicator-placeholder {
  margin-left: auto;
}

.wheel-legend-container {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid var(--light-border-color);
}
